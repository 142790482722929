.footer-view {
	padding-top: 44px;
	padding-bottom: 70px;

	.footer {
		padding-bottom: 34px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.3);
		.footerIcon {
			height: 24px;
		}
	}

	.footerList {
		.footerItem {
			margin-left: 100px;

			&:first-child {
				margin-left: 0;
			}
		}
	}
}

@media only screen and (max-width: 1020px) {
	.footer-view {
		padding-top: 0;
		padding-bottom: 40px;

		.footer {
			padding-bottom: 0;
			height: 86px;
			margin: 0 10px;
			justify-content: center;

			.logo {
				display: none;
			}
		}

		.footerList {
			flex-direction: column;
			align-items: center;
			
			.land {
				cursor: pointer;
			}

			.footerItem {
				margin-left: 0;
				text-align: center;

			}

			.footerItem2 {
				margin-top: 26px;
			}

			.footerItem3 {
				margin-top: 38px;
			}
		}

		.logo-footer {
			margin-top: 58px;
		}
	}
}