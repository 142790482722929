* {
  outline: none;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none !important;
  }
}

html,
body,
#root {
  font-family: "Montserrat-VariableFont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 24px;
}

body {
  margin: 0;
  box-sizing: border-box;
}

#root {
  position: relative;
}

.app {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  flex-direction: column;
  z-index: 1;
  background-size: cover;
  transition: all ease 0.33ms;
  color: #FFFFFF;
}

.font-family-NeonOne {
  font-family: "Neon-One";
}

.app-container {
  position: relative;
}

.font-weight-bold {
  font-weight: bold;  
  font-variation-settings: "wght" 700;
}

.MuiButtonBase-root {
  display: flex;
  align-items: center;
}

.container {
  width: 1440px;
  max-width: 100%;
}

.container2 {
  width: 1020px;
  max-width: 100%;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-align-items-center {
  align-items: center;
}

.flex-justify-content-center {
  justify-content: center;
}

.flex-justify-content-between {
  justify-content: space-between;
}

.flex-justify-content-end {
  justify-content: flex-end;
}

.ml-8 {
  margin-left: 8px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-20 {
  margin-left: 20px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-14 {
  margin-top: 14px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-26 {
  margin-top: 26px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-36 {
  margin-top: 36px;
}

.mt-40 {
  margin-top: 40px;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-24 {
  font-size: 24px;
}

.font-36 {
  font-size: 36px;
}

.font-40 {
  font-size: 40px;
}

.color1 {
  color: #FFFFFF;
}

.color2 {
  color: rgba(255, 255, 255, 0.5);
}

.color3 {
  color: #4AB2EE;
}

.color4 {
  color: #8F40FF;
}

.color5 {
  color: #3CDFD4;
}

.color6 {
  color: #18EEFD;
}

.color7 {
  color: rgba(143, 64, 255, 0.6);
}

.color8 {
  color: #D2B280;
}

.color9 {
  color: #000000;
}

.color10 {
  background-image: linear-gradient(to bottom, #FB4CEB, #00356F);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.color11 {
  color: #E08F10 !important;
}

.color12 {
  color: #FFA61B;
}

.color13 {
  color: #FEBF06;
}

.bg1 {
  background: #000000;
}

.border-radius-4 {
  border-radius: 4px;
}

.border-radius-8 {
  border-radius: 8px;
}

.text-center {
  text-align: center;
}

.text-shadow1 {
  text-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
}

a {
  text-decoration: none;
}

#ohm-popper,
#ohm-popper-pending {
  z-index: 5;
  background-color: #00000003;
  backdrop-filter: blur(33px);
  -webkit-backdrop-filter: blur(33px);
  margin-top: 10px;
  border-radius: 10px;

  .MuiPaper-root.ohm-menu {
    padding: 16px 16px;
    display: flex;
    flex-direction: column;
    // width: 200px;
    max-width: 200px;

    button {
      display: block;
      width: 100%;
      text-align: left;
      margin-bottom: 4px;
      padding-left: 16px;

      &:not(:hover) {
        background-color: #00000003 !important;
      }
    }

    a {
      svg {
        vertical-align: middle;
        font-size: 20px;
        margin-left: 3px;
      }
    }

    hr {
      margin-bottom: 18px;
    }
  }

  .add-tokens {
    >p {
      margin: 15px 0px 5px 0px;
      text-align: left;
      padding-left: 16px;
    }
  }

  .buy-tokens {
    padding-bottom: 8px;
  }
}

.data-row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 8px 0px;
}

.help-text {
  margin: 10px 0px;
}

.MuiButton-outlinedPrimary:hover {
  border: 1px solid #E08F10 !important;
}

img {
  max-width: 100%;
}

#bond-view {
  position: absolute !important;
  width: 100%;
  height: 100%;
  z-index: 69;

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .MuiBackdrop-root {
      background: rgba(100, 100, 100, 0.1) !important;
      backdrop-filter: blur(33px) !important;
      -webkit-backdrop-filter: blur(33px) !important;

      .ohm-card {
        opacity: 1 !important;
        height: auto;
      }
    }

    .ohm-modal {
      max-width: 688px;
      min-height: 605px;
    }
  }

  /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .MuiBackdrop-root {
      background: rgba(100, 100, 100, 0.95);

      .ohm-card {
        height: auto;
      }
    }

    .ohm-modal {
      max-width: 688px;
      min-height: 605px;
      opacity: 0.9;
    }
  }
}

@media only screen and (max-width: 1020px) {
  .container {
    width: 375px;
  }

  .container2 {}
}

@keyframes animate1 {
  0% {
    left: -100%;
  }

  50%,
  100% {
    left: 100%;
  }
}

@keyframes animate2 {
  0% {
    top: -100%;
  }

  50%,
  100% {
    top: 100%;
  }
}

@keyframes animate3 {
  0% {
    right: -100%;
  }

  50%,
  100% {
    right: 100%;
  }
}

@keyframes animate4 {
  0% {
    bottom: -100%;
  }

  50%,
  100% {
    bottom: 100%;
  }
}