.header-view {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 99;

	.header {
		width: 100%;
		height: 60px;
		background: rgba(0, 0, 0);
		position: fixed;
		top: 0;

		.container {
			padding: 0 24px;

			.menuList {
				.menuItem {
					margin-left: 40px;

					&:first-child {
						margin-left: 0;
					}

					div {
						transition: 0.5s;

						&:hover {
							color: #E203CF;
						}
					}
				}
			}
		}
	}

	.logoBox,.enterApp {
		width: 140px;
	}
	.enterApp {
		height: 36px;
		background: rgba(91, 0, 83, 0.3);
		border: 1px solid #7B07A8;
		transform: 0.35s;

		&:hover {
			cursor: pointer;
			color: #FFFFFF;
			background: #E203CF;
		}
	}
}

@media only screen and (max-width: 1020px) {
	.header-view {
		.header-m {
			padding-left: 18px;
			padding-right: 10px;
			height: 60px;
			background: rgba(0, 0, 0);
			position: fixed;
			top: 0;
			width: 100%;

		}

		.logoBox,
		.enterApp {
			width: 110px;
		}
	}

	.menu-container {
		width: 100vw;
		height: 100vh;
		background: linear-gradient(180deg, #864971 0%, #864971 0%, #630C86 47%, #471252 100%, #471252 100%);
		position: relative;

		.logo2 {
			margin-top: 120px;
		}

		.menuList {
			margin-top: 28px;

			.menuItem {
				margin-top: 16px;
				display: block;
				width: 284px;
				height: 44px;
				line-height: 44px;
				border: 1px solid #CB05CE;
			}
		}

		.close {
			margin-top: 60px;
		}
	}
}

.MuiLink-root.active {
	text-decoration: none !important;
}